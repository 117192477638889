import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import { Events, scrollSpy } from "react-scroll";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Header from "../base/Header";
import Footer from "../base/Footer";
import { useTranslation } from "react-i18next";
import AgentFloatingSupportButton from "../base/AgentFloatingSupportButton";

const PaymentMethodForm = () => {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const username = useSelector((state) => state.auth.username);
  const language = useSelector((state) => state.auth.language);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLoggedIn) {
      window.location.href = "/";
    }
  }, [isLoggedIn]);

  const checkoutId = location.state?.checkoutId;
  const paymentMethodName = location.state?.paymentMethodName;
  const formValues = location.state?.formValues;
  const price = location.state?.price;

  const [t, i18n] = useTranslation("global");

  const baseUrl = window.location.protocol + "//" + window.location.host;
  const successUrl = `${baseUrl}/agent/payment-success`;
  const failureUrl = `${baseUrl}/agent/payment-failed`;

  useEffect(() => {
    localStorage.setItem("payment", true);
    localStorage.setItem("paymentMethodName", paymentMethodName);
    localStorage.setItem("checkoutIdDetails", checkoutId);
    if (checkoutId) {
      // Inject the paymentWidgets.js script
      const paymentWidgetScript = document.createElement("script");
      paymentWidgetScript.src = `https://eu-prod.oppwa.com/v1/paymentWidgets.js?checkoutId=${checkoutId}`;
      paymentWidgetScript.async = true;
      document.body.appendChild(paymentWidgetScript);

      // Inject the wpwlOptions script after paymentWidgets.js is loaded
      paymentWidgetScript.onload = () => {
        const wpwlOptionsScript = document.createElement("script");
        wpwlOptionsScript.type = "text/javascript";
        wpwlOptionsScript.innerHTML = `
              var wpwlOptions = {
                paymentTarget: "_top",
                locale: '${language === "eng" ? "en" : "ar"}',
                onSuccess: function() {
                  window.location.href = '${successUrl}';
                },
                onFail: function() {
                  window.location.href = '${failureUrl}';
                }
              };
            `;
        document.body.appendChild(wpwlOptionsScript);
      };

      return () => {
        document.body.removeChild(paymentWidgetScript);
      };
    }
  }, [checkoutId]);

  useEffect(() => {
    Events.scrollEvent.register("begin", function (to, element) {});
    Events.scrollEvent.register("end", function (to, element) {});

    scrollSpy.update();

    return () => {
      Events.scrollEvent.remove("begin");
      Events.scrollEvent.remove("end");
    };
  }, []);

  return (
    <>
      {/* {isLoggedIn && (
        <h1>
          <Header />
          <div>
            {checkoutId ? (
              <div className="flex items-center justify-center min-h-screen">
                <div>
                  <div
                    className="flex justify-between"
                    dir={language === "ar" ? "rtl" : "ltr"}
                  >
                    <p className="mb-3 font-bold text-background_steel_blue">
                      {" "}
                      {t("hero.price_text")}: {price} {t("hero.sar_text")}
                    </p>
                  </div>
                  <form
                    action={successUrl}
                    className="paymentWidgets"
                    data-brands={
                      paymentMethodName === "mada"
                        ? "MADA"
                        : paymentMethodName === "Credit Card"
                        ? "VISA MASTER"
                        : ""
                    }
                  ></form>
                </div>
              </div>
            ) : (
              <p>Loading...</p>
            )}
          </div>

          <Footer />
        </>
      )} */}

      {isLoggedIn && (
        <h1>
          <Header />
          {checkoutId ? (
            <div className="flex items-center justify-center min-h-screen">
              <div>
                <div
                  className="flex justify-between"
                  dir={language === "ar" ? "rtl" : "ltr"}
                >
                  <p className="mb-3 font-bold text-background_steel_blue">
                    {" "}
                    {t("hero.price_text")}: {price} {t("hero.sar_text")}
                  </p>
                </div>
                <form
                  action={successUrl}
                  className="paymentWidgets"
                  data-brands={
                    paymentMethodName === "mada"
                      ? "MADA"
                      : paymentMethodName === "Credit Card"
                      ? "VISA MASTER"
                      : ""
                  }
                ></form>
              </div>
            </div>
          ) : (
            <p>Loading...</p>
          )}
          <Footer />
          <AgentFloatingSupportButton />
        </h1>
      )}
    </>
  );
};

export default PaymentMethodForm;
