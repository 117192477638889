import { Icon } from "@iconify/react/dist/iconify.js";
import React, { useEffect, useState } from "react";
import axiosInstance from "../../../Api";

const AgentFloatingSupportButton = () => {
  const API_BASE_URL = process.env.REACT_APP_BASE_URL_AMK_TEST;
  const [showIcons, setShowIcons] = useState(false);
  const [chatLink, setChatLink] = useState("");
  const [emailLink, setEmailLink] = useState("");

  const toggleIcons = () => {
    setShowIcons(!showIcons);
  };

  useEffect(() => {
    const getChatLink = async () => {
      try {
        const response = await axiosInstance.get(
          `${API_BASE_URL}/api/method/airport_transport.api.bookings.get_support_link`
        );
        if (response && response.status === 200) {
          setChatLink(response.data.data.chat); // Set the chat link from the API response
          setEmailLink(
            `mailto:${response.data.data.email}?subject=Support%20Request&body=Please%20provide%20details%20about%20your%20issue.`
          ); // Set the chat link from the API response
        }
      } catch (error) {
        console.log("Error fetching chat link:", error);
      }
    };
    getChatLink();
  }, []);

  const openEmail = () => {
    if (emailLink) {
      window.location.href = emailLink; // Open the email client with pre-filled details
    } else {
      alert("Email link is not available at the moment.");
    }
  };

  const openWhatsApp = () => {
    if (chatLink) {
      window.open(chatLink, "_blank"); // Open the WhatsApp chat link in a new tab
    } else {
      alert("Chat link is not available at the moment.");
    }
  };

  return (
    <div>
      <button
        onClick={openWhatsApp}
        // onClick={toggleIcons}
        className="fixed bottom-5 cursor-pointer right-5 p-4 rounded-full shadow-lg transition bg-background_steel_blue text-text_white border border-text_white animate-shake"
      >
        <Icon
          // icon="streamline:customer-support-1"
          icon="ri:whatsapp-fill"
          className="text-text_white text-3xl"
        />
      </button>

      <div
        className={`fixed right-7 ${
          showIcons ? "bottom-[100px]" : "bottom-5"
        } flex flex-col items-center gap-3 transition-all duration-300`}
      >
        {showIcons && (
          <>
            <button
              onClick={openWhatsApp}
              className="p-3 rounded-full bg-green-500 shadow-lg bg-background_steel_blue border border-text_white"
            >
              <Icon
                icon="ri:whatsapp-fill"
                className="text-text_white text-xl"
              />
            </button>
            <button
              onClick={openEmail}
              className="p-3 rounded-full bg-blue-500 shadow-lg bg-background_steel_blue border border-text_white"
            >
              <Icon
                icon="bi:envelope-fill"
                className="text-text_white text-xl"
              />
            </button>
          </>
        )}
      </div>
    </div>
  );
};
export default AgentFloatingSupportButton;
