import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Icon } from "@iconify/react";
import axios from "axios";

export default function UnderMaintenance() {
  const API_BASE_URL = process.env.REACT_APP_BASE_URL_AMK_TEST;
  const navigate = useNavigate();

  const [t, i18n] = useTranslation("global");
  const language = useSelector((state) => state.auth.language);

  useEffect(() => {
    const checkServiceStatus = async () => {
      try {
        const response = await axios.get(
          `${API_BASE_URL}/api/method/airport_transport.api.bookings.get_services?language=en`
        );

        // If response status is 200, redirect to home
        if (response.status === 200) {
          navigate("/");
        }
      } catch (error) {
        console.error("Error fetching service status", error);
      }
    };

    // Call the API every 10 seconds
    const interval = setInterval(checkServiceStatus, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(interval);
  }, [API_BASE_URL, navigate]);

  return (
    <>
      <div className="h-screen w-screen position relative">
        <div
          className="position absolute left-0 top-0"
          dir={language === "ar" ? "rtl" : "ltr"}
        >
          <img
            src="./assets/signin/left_vector.png"
            alt="left_vector"
            className="w-24 h-24 md:w-48 md:h-48"
          />
        </div>
        <div
          className="position absolute right-0 bottom-0"
          dir={language === "ar" ? "rtl" : "ltr"}
        >
          <img
            src="./assets/signin/right_vector.png"
            alt="right_vector"
            className="w-16 md:w-48 h-12 md:h-36"
          />
        </div>

        <main dir={language === "ar" ? "rtl" : "ltr"}>
          {/* Form Section */}
          <div className="mx-auto p-4 h-screen flex items-center justify-center">
            <div>
              <img
                src="./assets/maintainance/underMaintenance.png"
                alt="404 Page"
                className="m-auto"
              />
              <h1 className="text-center text-2xl font-bold mt-4">
                {t("maintenance_text")}
              </h1>
              <div className="flex justify-center w-full mt-4"></div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
