import React from "react";
import Header from "../base/Header";
import { useSelector } from "react-redux";
import Heading from "../base/Heading";
import Paragraph from "../base/Paragraph";
import Footer from "../base/Footer";
import { Icon } from "@iconify/react/dist/iconify.js";
import { useTranslation } from "react-i18next";
import ReviewsSlider from "./ReviewsSlider";
import AgentFloatingSupportButton from "../base/AgentFloatingSupportButton";

export default function AgentAboutUsLayout() {
  const [t, i18n] = useTranslation("global");
  const language = useSelector((state) => state.auth.language);

  const reviewsList = [
    {
      id: 1,
      customer_comment: "This is first comment",
      customer_name: "Name Surname",
      customer_designation: "Position, Company name",
    },
    {
      id: 2,
      customer_comment: "This is second comment",
      customer_name: "Name Surname",
      customer_designation: "Position, Company name",
    },
    {
      id: 2,
      customer_comment: "This is third comment",
      customer_name: "Name Surname",
      customer_designation: "Position, Company name",
    },
  ];
  return (
    <>
      <div>
        <Header />

        <div>
          {/* first hero section */}
          <div
            className="h-[429px] flex flex-col mt-0 md:mt-0 lg:mt-0 xl:mt-20 bg-airport_ride_bg  transition-all duration-300 mx-auto bg-cover bg-fixed bg-center bg-no-repeat"
            dir={language === "ar" ? "rtl" : "ltr"}
          >
            <div className="h-[429px] inset-0 bg-[#E9B838c6] flex flex-col justify-center items-center text-center px-3 md:px-6 lg:px-16 xl:px-0">
              <div className="mb-3">
                <Heading
                  title={t("about_us.hero_heading")}
                  className={"font-bold text-3xl md:text-5xl text-text_white"}
                />
              </div>
              <div className="mt-3 mx-3 md:mx-3 lg:mx-40">
                <Paragraph
                  title={t("about_us.hero_paragraph")}
                  className={"font-normal text-base md:text-xl text-text_white"}
                />
              </div>
            </div>
          </div>
          {/* first hero section */}

          {/* content section */}
          <div
            dir={language === "ar" ? "rtl" : "ltr"}
            className={`mx-6 md:mx-10 lg:mx-16 xl:mx-20 my-6 md:my-6 lg:my-16 xl:my-28 flex flex-col-reverse md:flex-col lg:flex-row xl:flex-row justify-between items-center`}
          >
            <div className="w-full md:w-full lg:w-1/2 xl:w-1/2 mx-1">
              <div className="my-2 md:my-3 lg:my-4 xl:my-5">
                <Heading
                  title={t("about_us.section_heading")}
                  className={"font-bold text-2xl md:text-5xl text-text_black"}
                />
              </div>
              <div className="my-2 md:my-3 lg:my-4 xl:my-5 text-sm md:text-sm lg:text-base xl:text-base">
                {t("about_us.section_first_paragraph")}
              </div>
              <div className="my-2 md:my-3 lg:my-4 xl:my-5 text-sm md:text-sm lg:text-base xl:text-base">
                {t("about_us.section_second_paragraph")}
              </div>
              <div className="my-2 md:my-3 lg:my-4 xl:my-5 text-sm md:text-sm lg:text-base xl:text-base">
                {t("about_us.section_third_paragraph")}
              </div>
            </div>
            <div className="w-full md:w-full lg:w-1/2 xl:w-1/2 mx-1">
              <img
                src="/assets/aboutus/about_us_img.png"
                alt="Our Services"
                className="w-full h-full"
              />
            </div>
          </div>
          {/* content section */}

          {/* review section */}
          <ReviewsSlider reviewsList={reviewsList} />
          {/* review section */}

          {/* footer section */}
          <Footer />
          {/* footer section */}
          <AgentFloatingSupportButton />
        </div>
      </div>
    </>
  );
}
