import React from "react";
import { Icon } from "@iconify/react/dist/iconify.js";
import { useTranslation } from "react-i18next";
import Heading from "../base/Heading";
import { useSelector } from "react-redux";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export default function ReviewsSlider({ reviewsList }) {
  const [t, i18n] = useTranslation("global");
  const language = useSelector((state) => state.auth.language);

  const settings = {
    dots: true,
    arrows: true, // Changed 'arrow' to 'arrows'
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
  };

  return (
    <>
      <div className="h-[350px]  bg-background_white md:bg-[#cacaca6d]">
        <Slider className="reviews-first-slider" {...settings}>
          {reviewsList &&
            reviewsList.map((review, id) => {
              return (
                <div
                  key={id}
                  className="px-6 md:px-10 lg:px-16 xl:px-56 py-3 md:py-6 lg:py-10 xl:py-18 flex flex-col justify-start items-center"
                >
                  <div className="my-3 flex flex-row justify-center items-center">
                    <Icon icon="tabler:star-filled" className="text-lg mx-1" />
                    <Icon icon="tabler:star-filled" className="text-lg mx-1" />
                    <Icon icon="tabler:star-filled" className="text-lg mx-1" />
                    <Icon icon="tabler:star-filled" className="text-lg mx-1" />
                    <Icon icon="tabler:star-filled" className="text-lg mx-1" />
                  </div>
                  <div className="my-3">
                    <Heading
                      title={t("about_us.customer_comment")}
                      className={
                        "font-bold text-base md:text-2xl text-text_black text-center"
                      }
                    />
                    {/* <Heading
                    title={review.customer_comment}
                    className={
                      "font-bold text-base md:text-2xl text-text_black text-center"
                    }
                  /> */}
                  </div>
                  <div
                    className="my-3 flex flex-col md:flex-row justify-center items-center"
                    dir={language === "ar" ? "rtl" : "ltr"}
                  >
                    <div className="mx-2">
                      <img
                        src="./assets/aboutus/placeholder_img.png"
                        alt="Placeholder"
                        className="w-16 h-16 rounded-full"
                      />
                    </div>
                    <div className="mx-2 flex flex-col sm:flex-col justify-center items-center md:flex-col md:justify-start md:items-start">
                      <div className="font-bold">
                        {t("about_us.customer_name")}
                      </div>
                      <div>{t("about_us.customer_designation")}</div>
                    </div>
                  </div>
                </div>
              );
            })}
        </Slider>
      </div>
    </>
  );
}
