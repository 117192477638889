import React, { useState, useEffect } from "react";
import { Link, Events, scrollSpy } from "react-scroll";
import HeroSection from "../hero/HeroSection";
import ServiceSection from "../services/ServiceSection";
import VehicleSection from "../vehicleType/VehicleSection";
import Button from "../base/Button";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useMedia } from "use-media";
import { Icon } from "@iconify/react/dist/iconify.js";
import axiosInstance from "../../Api";

export default function Footer() {
  const API_BASE_URL = process.env.REACT_APP_BASE_URL_AMK_TEST;
  const navigate = useNavigate();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  const isSmall = useMedia({ maxWidth: "768px" }); // Mobile view
  const isMedium = useMedia({ minWidth: "769px", maxWidth: "1024px" }); // Tablet view
  const isLarge = useMedia({ minWidth: "1025px" }); // Desktop view

  // Function to toggle menu
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const language = useSelector((state) => state.auth.language);
  const [t, i18n] = useTranslation("global");

  const footerItems = [
    { id: "home", text: t("footer.items.home_text"), component: HeroSection },
    {
      id: "services",
      text: t("footer.items.services_text"),
      component: ServiceSection,
    },
    {
      id: "vehicleType",
      text: t("footer.items.vehicle_text"),
      component: VehicleSection,
    },
  ];

  const footerItemsTwo = [
    // { text: t("footer.items_2.privacy_text"), href: "#" },
    {
      text: t("footer.items_2.terms_text"),
      href: "/terms-condition",
    },
    // { text: t("footer.items_2.cookies_text"), href: "#" },
  ];

  useEffect(() => {
    Events.scrollEvent.register("begin", function (to, element) {});
    Events.scrollEvent.register("end", function (to, element) {});

    scrollSpy.update();

    return () => {
      Events.scrollEvent.remove("begin");
      Events.scrollEvent.remove("end");
    };
  }, []);

  const [chatLink, setChatLink] = useState("");
  const [emailLink, setEmailLink] = useState("");

  useEffect(() => {
    const getChatLink = async () => {
      try {
        const response = await axiosInstance.get(
          `${API_BASE_URL}/api/method/airport_transport.api.bookings.get_support_link`
        );
        if (response && response.status === 200) {
          setChatLink(response.data.data.chat); // Set the chat link from the API response
          setEmailLink(
            `mailto:${response.data.data.email}?subject=Support%20Request&body=Please%20provide%20details%20about%20your%20issue.`
          ); // Set the chat link from the API response
        }
      } catch (error) {
        console.log("Error fetching chat link:", error);
      }
    };
    getChatLink();
  }, []);
  const openEmail = () => {
    if (emailLink) {
      window.location.href = emailLink; // Open the email client with pre-filled details
    } else {
      alert("Email link is not available at the moment.");
    }
  };

  const openWhatsApp = () => {
    if (chatLink) {
      window.open(chatLink, "_blank"); // Open the WhatsApp chat link in a new tab
    } else {
      alert("Chat link is not available at the moment.");
    }
  };

  return (
    <>
      <div>
        {/* footer code is here */}
        {/* <footer
          className="w-full py-3 md:py-6 px-10 md:px-20 flex flex-col items-center justify-center bg-background_steel_blue text-text_white"
          dir={language === "ar" ? "rtl" : "ltr"}
        >
          <div className="mb-4">
            <img
              src="./assets/footer/logo.png"
              alt="Mashrook Trips"
              className="w-16 md:w-32 h-16 md:h-32"
            />
          </div>
          <div className="my-2 md:my-4">
            <ul className="flex flex-col items-center mt-2 md:mt-4 font-normal md:font-medium md:flex-row md:items-baseline lg:space-x-8 lg:mt-0">
              {footerItems.map((item) => (
                <li
                  key={item.id}
                  className="w-[70px] flex flex-row justify-center items-center"
                >
                  <Link
                    to={item.id}
                    spy={true}
                    smooth={true}
                    onClick={toggleMenu} // Close menu on link click
                    className="text-text_white block cursor-pointer py-1 md:py-2 pr-2 md:pr-4 pl-2 md:pl-3 text-white rounded bg-primary-700 lg:bg-transparent lg:text-primary-700 lg:p-0 dark:text-white"
                  >
                    {item.text}
                  </Link>
                </li>
              ))}
              {!isLoggedIn && (
                <li
                  onClick={() => navigate("/sign-in")}
                  className="text-text_white block cursor-pointer py-1 md:py-2 pr-2 md:pr-4 pl-2 md:pl-3 text-white rounded bg-primary-700 lg:bg-transparent lg:text-primary-700 lg:p-0 dark:text-white"
                >
                  {t("footer.sign_in_text")}
                </li>
              )}
              {!isLoggedIn && (
                <li>
                  <Button
                    className="hidden md:flex cursor-pointer text-text_steel_blue bg-background_white border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
                    onClick={() =>
                      navigate("/agent/create-new-account")
                    }
                    label={t("footer.create_text")}
                    type="button"
                  />
                </li>
              )}
            </ul>
          </div>
          <div className="mt-4 border-t w-full pt-2 flex flex-col-reverse md:flex-row justify-between items-center">
            <div className="mt-3 md:0">{t("footer.copyright_text")}</div>
            <div className="flex flex-row justify-end items-center">
              <ul className="flex flex-col md:flex-row items-center mt-3 text-sm font-medium text-gray-500 dark:text-gray-400 sm:mt-0">
                {footerItemsTwo.map((item, index) => (
                  <li key={index} className="mt-3 md:0">
                    <a
                      href={item.href}
                      className="hover:underline me-4 md:me-6"
                    >
                      {item.text}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </footer> */}
        {/* footer code is here */}

        <footer className="px-10 md:px-20 py-5 md:py-10">
          <div
            className="py-4 md:py-0 w-full border-b border-background_steel_blue flex flex-col md:flex-row items-center justify-between bg-text_white text-black"
            dir={language === "ar" ? "rtl" : "ltr"}
          >
            <div className="mb-4">
              <img
                src="./assets/footer/brownlogo.png"
                alt="Mashrook Trips"
                className="w-16 md:w-20 lg:w-20 h-20 md:h-24 lg:h-24"
              />
            </div>
            <div className="my-2 md:my-4">
              <ul className="flex flex-col items-center mt-2 md:mt-4 font-normal md:font-medium md:flex-row md:items-baseline lg:space-x-8 lg:mt-0">
                {/* {footerItems.map((item) => (
                  <li
                    key={item.id}
                    className="w-full md:w-[100px] flex flex-row justify-center items-center"
                  >
                    <Link
                      to={item.id}
                      spy={true}
                      smooth={true}
                      onClick={toggleMenu} // Close menu on link click
                      className="text-background_steel_blue block cursor-pointer py-1 md:py-2 pr-2 md:pr-4 pl-2 md:pl-3 text-white rounded bg-primary-700 lg:bg-transparent lg:text-primary-700 lg:p-0 dark:text-white"
                    >
                      {item.text}
                    </Link>
                  </li>
                ))} */}
                {/* <li
                  onClick={() => navigate("/about-us")}
                  className="text-background_steel_blue block cursor-pointer py-1 md:py-2 pr-2 md:pr-4 pl-2 md:pl-3 text-white rounded bg-primary-700 lg:bg-transparent lg:text-primary-700 lg:p-0 dark:text-white"
                >
                  {t("about_us_text")}
                </li> */}
                {/* {!isLoggedIn && (
                  <li
                    onClick={() => navigate("/sign-in")}
                    className="text-background_steel_blue block cursor-pointer py-1 md:py-2 pr-2 md:pr-4 pl-2 md:pl-3 text-white rounded bg-primary-700 lg:bg-transparent lg:text-primary-700 lg:p-0 dark:text-white"
                  >
                    {t("footer.sign_in_text")}
                  </li>
                )}
                {!isLoggedIn && (
                  <li>
                    <Button
                      className="hidden md:flex cursor-pointer text-text_white bg-background_steel_blue border border-gray-300 focus:outline-none hover:bg-gray-100 focus:ring-4 focus:ring-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700"
                      onClick={() =>
                        navigate("/agent/create-new-account")
                      }
                      label={t("footer.create_text")}
                      type="button"
                    />
                  </li>
                )} */}
              </ul>
            </div>
            {/* <div className="flex flex-row justify-end items-center">
              {isSmall && (
                <>
                  <div
                    className="mr-1 bg-background_steel_blue p-2 rounded-full cursor-pointer"
                    onClick={openEmail}
                  >
                    <Icon
                      icon="bi:envelope-fill"
                      className="text-text_white text-xl"
                    />
                  </div>
                  <div
                    className="mr-1 bg-background_steel_blue p-2 rounded-full cursor-pointer"
                    onClick={openWhatsApp}
                  >
                    <Icon
                      icon="ri:whatsapp-fill"
                      className="text-text_white text-xl"
                    />
                  </div>
                </>
              )}

              {isMedium && (
                <>
                  <div className="h-40 group relative w-64 bg-gray-300 rounded-lg p-4 flex items-center justify-center cursor-pointer overflow-hidden">
                    <div className="absolute inset-0 flex items-center justify-center transition-all duration-300 ease-in-out">
                      <span className="bg-background_steel_blue rounded-full px-2 py-2 text-lg font-semibold text-gray-700 group-hover:opacity-0 transition-opacity duration-300 ease-in-out">
                        <Icon
                          icon="streamline:customer-support-1"
                          className="text-text_white text-xl"
                        />
                      </span>
                      <span className="bg-background_steel_blue rounded-full px-2 py-2 absolute text-md font-semibold text-red-600 opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out">
                        <Icon
                          icon="gridicons:cross"
                          className="text-text_white text-xl"
                        />
                      </span>
                    </div>
                    <div className="absolute inset-0 flex flex-row items-center justify-center space-y-2 opacity-0 group-hover:opacity-100 transition-all duration-300 ease-in-out transform group-hover:-translate-y-10">
                      <div
                        className="bg-background_steel_blue text-white px-2 py-2 rounded-full cursor-pointer mr-4"
                        onClick={openEmail}
                      >
                        <Icon
                          icon="bi:envelope-fill"
                          className="text-text_white text-xl"
                        />
                      </div>
                      <div
                        className="bg-background_steel_blue text-white px-2 py-2 rounded-full cursor-pointer ml-4"
                        onClick={openWhatsApp}
                      >
                        <Icon
                          icon="ri:whatsapp-fill"
                          className="text-text_white text-xl"
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}

              {isLarge && (
                <>
                  <div className="h-40 group relative w-64 bg-gray-300 rounded-lg p-4 flex items-center justify-center cursor-pointer overflow-hidden">
                    <div className="absolute inset-0 flex items-center justify-center transition-all duration-300 ease-in-out">
                      <span className="bg-background_steel_blue rounded-full px-2 py-2 text-lg font-semibold text-gray-700 group-hover:opacity-0 transition-opacity duration-300 ease-in-out">
                        <Icon
                          icon="streamline:customer-support-1"
                          className="text-text_white text-xl"
                        />
                      </span>
                      <span className="bg-background_steel_blue rounded-full px-2 py-2 absolute text-md font-semibold text-red-600 opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out">
                        <Icon
                          icon="gridicons:cross"
                          className="text-text_white text-xl"
                        />
                      </span>
                    </div>

                    <div className="absolute inset-0 flex flex-row items-center justify-center space-y-2 opacity-0 group-hover:opacity-100 transition-all duration-300 ease-in-out transform group-hover:-translate-y-10">
                      <div
                        className="bg-background_steel_blue text-white px-2 py-2 rounded-full cursor-pointer mr-4"
                        onClick={openEmail}
                      >
                        <Icon
                          icon="bi:envelope-fill"
                          className="text-text_white text-xl"
                        />
                      </div>
                      <div
                        className="bg-background_steel_blue text-white px-2 py-2 rounded-full cursor-pointer ml-4"
                        onClick={openWhatsApp}
                      >
                        <Icon
                          icon="ri:whatsapp-fill"
                          className="text-text_white text-xl"
                        />
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div> */}
          </div>
          <div className="mt-4 w-full pb-2 flex flex-col md:flex-row justify-center md:justify-between items-center">
            <div className="mt-3 md:0 text-background_steel_blue">
              {t("footer.copyright_text")}
            </div>
            <div className="flex flex-row justify-end items-center">
              <ul className="flex flex-col md:flex-row justify-start md:justify-start items-center mt-3 text-sm font-medium text-gray-500 dark:text-gray-400 sm:mt-0">
                {footerItemsTwo.map((item, index) => (
                  <li
                    key={index}
                    className="mt-3 md:0  text-background_steel_blue"
                  >
                    <a
                      href={item.href}
                      className="hover:underline me-4 md:me-6"
                    >
                      {item.text}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          </div>

          {/* <div className="flex flex-col justify-center items-center">
            <div className="my-2 md:my-5">
              <img
                src="./assets/footer/tags.png"
                className="w-[210px] h-[68px]"
              />
            </div>
            <div className="text-background_steel_blue text-center md:text-left">
              Ali Mustafa Kwanini Company - AMK. Commercial registration number
              for the online store 205134432505. Tax number 30046232310003
            </div>
          </div> */}
        </footer>
      </div>
    </>
  );
}
