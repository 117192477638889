import React, { useState, useRef, useEffect } from "react";
import {
  Autocomplete,
  GoogleMap,
  LoadScript,
  Marker,
  Polygon,
  Polyline,
  StandaloneSearchBox,
} from "@react-google-maps/api";
import axios from "axios";
import { setLoading } from "../../redux/actions/loaderAction";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Icon } from "@iconify/react/dist/iconify.js";
import { useMedia } from "use-media";
import "./MapModal.css";
import Button from "./Button";
import InputFieldFormik from "./InputFieldFormik";
import { Input } from "antd";
import pickupIcon from "../../assets/pickup.png";
import dropoffIcon from "../../assets/dropoff.png";
import axiosInstance from "../../Api";

export default function MapModal({
  isModalOpen,
  setIsModalOpen,
  openModal,
  closeModal,
  error,
  setError,
  rideName,
  formValues,
  setFormValues,
  onSubmitDestination,
  zoneCoords,
  cityName,
  location,
  setLocation,
  destination,
  setDestination,
}) {
  const commonProps = {
    className: "text-gray-900 text-sm block w-full",
  };
  const [t, i18n] = useTranslation("global");
  const dispatch = useDispatch();
  const [selectedPickup, setSelectedPickup] = useState(null);
  const [selectedDropoff, setSelectedDropoff] = useState(null);
  const [searchBox, setSearchBox] = useState(null); // State to hold the StandaloneSearchBox instance
  const [searchBoxPickup, setSearchBoxPickup] = useState(null);
  const [searchBoxDropoff, setSearchBoxDropoff] = useState(null);
  const [currentLocation, setCurrentLocation] = useState(null);
  const API_BASE_URL = process.env.REACT_APP_BASE_URL_AMK_TEST;
  const language = useSelector((state) => state.auth.language);

  const isSmallScreen = useMedia("(max-width: 768px)");
  const mapRef = useRef(null);

  const containerStyle = {
    width: "100%",
    height: `${isSmallScreen ? "50vh" : "100vh"}`,
  };

  let center;
  if (cityName === t("hero.dammam_text")) {
    if (selectedPickup) {
      center = selectedPickup;
    } else if (selectedDropoff) {
      center = selectedDropoff;
    } else {
      center = {
        lat: 26.3927,
        lng: 49.9777,
      };
    }
  }
  if (cityName === t("hero.riyadh_text")) {
    if (selectedPickup) {
      center = selectedPickup;
    } else if (selectedDropoff) {
      center = selectedDropoff;
    } else {
      center = {
        lng: 46.6753,
        lat: 24.7136,
      };
    }
  }

  const convertedCoords = zoneCoords.map((coord) =>
    coord.map.map((coo) => ({
      lat: coo[0],
      lng: coo[1],
    }))
  );
  // let convertedCoords2 = null;
  // if (zoneCoords2 !== null) {
  //   convertedCoords2 = zoneCoords.map((coord) => ({
  //     lat: coord[0],
  //     lng: coord[1],
  //   }));
  // }
  useEffect(() => {
    const setPickOrDrop = async () => {
      if (rideName === "airportRide") {
        dispatch(setLoading(true));
        let point;
        const apiKey = "AIzaSyBMTLXpuXtkEfbgChZzsj7LPYlpGxHI9iU";
        const encodedAddress = encodeURIComponent(
          `${formValues.airportName} ${formValues.terminalNumber}`
        ); // Encode the address
        const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodedAddress}&key=${apiKey}`;
        try {
          const response = await axios.get(url);
          const location = response.data.results[0].geometry.location;
          const latitude = location.lat;
          const longitude = location.lng;
          point = { lat: latitude, lng: longitude };
        } catch (error) {
          console.error("Error fetching location:", error.message);
        }
        if (cityName === t("hero.dammam_text")) {
          const Dammampoint = { lat: 26.3927, lng: 49.9777 };
          if (formValues.rideType === t("hero.pickup_value_text")) {
            setLocation(
              `${formValues.airportName} ${formValues.terminalNumber} ${formValues.arrivalCity} Saudi Arabia`
            );
            setSelectedPickup(point);
          } else if (formValues.rideType === t("hero.dropoff_value_text")) {
            setDestination(
              `${formValues.airportName} ${formValues.terminalNumber} ${formValues.arrivalCity} Saudi Arabia`
            );
            setSelectedDropoff(point);
          }
        } else {
          const Riyadhpoint = { lng: 46.6753, lat: 24.7136 };
          if (formValues.rideType === t("hero.pickup_value_text")) {
            setLocation(
              `${formValues.airportName} ${formValues.terminalNumber} ${formValues.arrivalCity} Saudi Arabia`
            );
            setSelectedPickup(point);
          } else if (formValues.rideType === t("hero.dropoff_value_text")) {
            setDestination(
              `${formValues.airportName} ${formValues.terminalNumber} ${formValues.arrivalCity} Saudi Arabia`
            );
            setSelectedDropoff(point);
          }
        }
        dispatch(setLoading(false));
      }
    };
    setPickOrDrop();
  }, [rideName, cityName, formValues.rideType]);

  const isPointInPolygon = (point, polygon, index) => {
    const { lat, lng } = point;
    let inside = false;
    if (index) {
      for (
        let i = 0, j = polygon[index].length - 1;
        i < polygon[index].length;
        j = i++
      ) {
        const xi = polygon[index][i].lat,
          yi = polygon[index][i].lng;
        const xj = polygon[index][j].lat,
          yj = polygon[index][j].lng;

        const intersect =
          yi > lng !== yj > lng &&
          lat < ((xj - xi) * (lng - yi)) / (yj - yi) + xi;
        if (intersect) inside = !inside;
      }
      return inside;
    } else {
      for (
        let i = 0, j = polygon[0].length - 1;
        i < polygon[0].length;
        j = i++
      ) {
        const xi = polygon[0][i].lat,
          yi = polygon[0][i].lng;
        const xj = polygon[0][j].lat,
          yj = polygon[0][j].lng;

        const intersect =
          yi > lng !== yj > lng &&
          lat < ((xj - xi) * (lng - yi)) / (yj - yi) + xi;
        if (intersect) inside = !inside;
      }
      return inside;
    }
  };

  const onMapClick = async (event, index) => {
    // if (index) {
    const point = { lat: event.latLng.lat(), lng: event.latLng.lng() };
    if (isPointInPolygon(point, convertedCoords, index)) {
      console.log("poly");
      setError("");
      dispatch(setLoading(true));
      if (rideName === "airportRide") {
        if (formValues.rideType === t("hero.dropoff_value_text")) {
          if (!selectedPickup) {
            const apiKey = "AIzaSyBMTLXpuXtkEfbgChZzsj7LPYlpGxHI9iU";
            const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${point.lat},${point.lng}&key=${apiKey}`;

            try {
              const response = await axios.get(url);
              const location = response.data.results[0].formatted_address;
              // console.log('Location:', location);
              setLocation(`${location} Saudi Arabia`);
              setSelectedPickup(point);
            } catch (error) {
              console.error("Error fetching location:", error.message);
            }
          }
        } else {
          if (!selectedPickup) {
            const apiKey = "AIzaSyBMTLXpuXtkEfbgChZzsj7LPYlpGxHI9iU";
            const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${point.lat},${point.lng}&key=${apiKey}`;

            try {
              const response = await axios.get(url);
              const location = response.data.results[0].formatted_address;
              // console.log('Location:', location);
              setLocation(`${location} Saudi Arabia`);
              setSelectedPickup(point);
            } catch (error) {
              console.error("Error fetching location:", error.message);
            }
          } else {
            const apiKey = "AIzaSyBMTLXpuXtkEfbgChZzsj7LPYlpGxHI9iU";
            const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${point.lat},${point.lng}&key=${apiKey}`;

            try {
              const response = await axios.get(url);
              const location = response.data.results[0].formatted_address;
              // console.log('Location:', location);
              setDestination(`${location} Saudi Arabia`);
              setSelectedDropoff(point);
            } catch (error) {
              console.error("Error fetching location:", error.message);
            }
          }
        }
      } else if (rideName === "scheduledRide") {
        if (!selectedPickup) {
          const apiKey = "AIzaSyBMTLXpuXtkEfbgChZzsj7LPYlpGxHI9iU";
          const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${point.lat},${point.lng}&key=${apiKey}`;

          try {
            const response = await axios.get(url);
            const location = response.data.results[0].formatted_address;
            // console.log('Location:', location);
            setLocation(`${location} Saudi Arabia`);
            setSelectedPickup(point);
          } catch (error) {
            console.error("Error fetching location:", error.message);
          }
        } else {
          const apiKey = "AIzaSyBMTLXpuXtkEfbgChZzsj7LPYlpGxHI9iU";
          const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${point.lat},${point.lng}&key=${apiKey}`;

          try {
            const response = await axios.get(url);
            const location = response.data.results[0].formatted_address;
            // console.log('Location:', location);
            setDestination(`${location} Saudi Arabia`);
            setSelectedDropoff(point);
          } catch (error) {
            console.error("Error fetching location:", error.message);
          }
        }
      } else {
        // if (!selectedPickup) {
        const apiKey = "AIzaSyBMTLXpuXtkEfbgChZzsj7LPYlpGxHI9iU";
        const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${point.lat},${point.lng}&key=${apiKey}`;

        try {
          const response = await axios.get(url);
          const location = response.data.results[0].formatted_address;
          // console.log('Location:', location);
          setLocation(`${location} Saudi Arabia`);
        } catch (error) {
          console.error("Error fetching location:", error.message);
        }
        setSelectedPickup(point);
        // }
      }
    } else {
      setError("You cannot select a point outside the service area");
    }
    dispatch(setLoading(false));
  };

  async function submitDestination() {
    if (rideName === "airportRide" || rideName === "scheduledRide") {
      if (selectedPickup && selectedDropoff) {
        try {
          const response = await axiosInstance.get(
            `${API_BASE_URL}/api/method/airport_transport.api.bookings.check_booking_zone?coordinates=${
              selectedDropoff.lat
            },${selectedDropoff.lng}&service=${
              rideName === "airportRide" ? "Airport Trip" : "City Trip"
            }&city=${cityName}&language=${language}&pick_up=${
              selectedPickup.lat
            },${selectedPickup.lng}`
          );
          if (response && response.status === 200) {
            onSubmitDestination(selectedPickup, selectedDropoff);
            setFormValues((preStates) => ({
              ...preStates,
              location: location,
              destination: destination,
            }));
            closeModal();
          }
        } catch (error) {
          console.log("Error", error);
          if (error.response.status === 400) {
            setError(error?.response?.data?.msg);
          }
        }
      } else {
        setError("Please select both pickup and dropoff locations.");
      }
    } else {
      if (selectedPickup) {
        onSubmitDestination(selectedPickup, null);
        setFormValues((preStates) => ({
          ...preStates,
          location: location,
        }));
        closeModal();
      } else {
        setError("Please select pickup location.");
      }
    }
  }

  const onLoad = (ref) => {
    mapRef.current = ref;
  };

  const onPickupSearchBoxLoad = (ref) => {
    setSearchBoxPickup(ref);
  };

  const onPlacesChangedPickup = () => {
    const places = searchBoxPickup.getPlaces();
    if (places?.length === 0) {
      return;
    }
    const place = places[0];
    const formattedAddress = place.formatted_address || "";
    setLocation(formattedAddress);
    fetchLocationPoint(formattedAddress);
  };

  const onDropoffSearchBoxLoad = (ref) => {
    setSearchBoxDropoff(ref);
  };

  const onPlacesChangedDropoff = () => {
    const places = searchBoxDropoff.getPlaces();
    if (places?.length === 0) {
      return;
    }
    const place = places[0];
    const formattedAddress = place.formatted_address || "";
    setDestination(formattedAddress);
    fetchDestinationPoint(formattedAddress);
  };

  useEffect(() => {
    if (isModalOpen) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [isModalOpen]);

  const mapOptions = {
    streetViewControl: false, // Hides the Street View control
    zoomControl: true,
    fullscreenControl: false,
    gestureHandling: "greedy",
  };

  const fetchLocationPoint = async (address) => {
    const apiKey = "AIzaSyBMTLXpuXtkEfbgChZzsj7LPYlpGxHI9iU";
    const ApiUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${apiKey}`;
    const response = await axios.get(ApiUrl);
    console.log(
      "what is inside response?",
      response.data.results[0].geometry.location
    );

    if (response?.data?.results.length > 0) {
      const currentPoints = response.data.results[1].geometry.location;
      if (isPointInPolygon(currentPoints, convertedCoords, null)) {
        setSelectedPickup(response.data.results[1].geometry.location);
        setError("");
      } else {
        setError("You cannot select a point outside the service area");
      }
    }
  };

  const fetchDestinationPoint = async (address) => {
    const apiKey = "AIzaSyBMTLXpuXtkEfbgChZzsj7LPYlpGxHI9iU";
    const ApiUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${apiKey}`;
    const response = await axios.get(ApiUrl);
    console.log("what is inside destination response?", response.data);
    if (response?.data?.results.length > 0) {
      const currentPoints = response.data.results[1].geometry.location;
      if (isPointInPolygon(currentPoints, convertedCoords, null)) {
        setSelectedDropoff(response.data.results[1].geometry.location);
        setError("");
      } else {
        setError("You cannot select a point outside the service area");
      }
    }
  };

  useEffect(() => {
    if (selectedPickup === null) {
      setLocation("");
    }
    if (selectedDropoff === null) {
      setDestination("");
    }
  }, [selectedPickup, selectedDropoff]);

  const handleCurrentLocation = () => {
    setError("");
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const currentLocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          // Center the map to the user's current location
          // mapRef.current.panTo(currentLocation);
          // setCurrentLocation(currentLocation);
          if (isPointInPolygon(currentLocation, convertedCoords, null)) {
            if (rideName === "airportRide") {
              if (formValues.rideType === t("hero.pickup_value_text")) {
                setSelectedDropoff(currentLocation);
              } else {
                setSelectedPickup(currentLocation);
              }
            } else if (rideName === "scheduledRide") {
              if (!selectedPickup) {
                setSelectedPickup(currentLocation);
              } else {
                setSelectedDropoff(currentLocation);
              }
            } else if (rideName === "rideByHour") {
              setSelectedPickup(currentLocation);
            }
            mapRef.current.panTo(currentLocation);
          } else {
            setError("You cannot select a point outside the service area");
          }
        },
        () => {
          alert(
            "Geolocation is not supported by this browser or permission denied."
          );
        }
      );
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  };

  const isPickupReadOnly = () => {
    if (rideName === "airportRide") {
      if (formValues.rideType === t("hero.pickup_value_text")) {
        return true;
      } else {
        return false;
      }
    } else if (rideName === "scheduledRide" || rideName === "rideByHour") {
      return false;
    } else {
      return true;
    }
  };

  const isDropoffReadOnly = () => {
    if (rideName === "airportRide") {
      if (formValues.rideType === t("hero.dropoff_value_text")) {
        return true;
      } else {
        return false;
      }
    } else if (rideName === "scheduledRide") {
      return false;
    } else {
      return true;
    }
  };

  return (
    <>
      {isModalOpen && (
        <div
          id="extralarge-modal"
          tabIndex="-1"
          className="fixed top-0 left-0 right-0 z-50 flex justify-center items-center w-full h-full max-h-full bg-black bg-opacity-50 overflow-hidden"
        >
          <LoadScript
            googleMapsApiKey="AIzaSyBMTLXpuXtkEfbgChZzsj7LPYlpGxHI9iU"
            libraries={["places", "geometry"]}
            region="sa"
          >
            <div className="relative w-full max-w-full max-h-full h-full overflow-hidden">
              <div className="relative bg-background_grey rounded-lg shadow dark:bg-gray-700 overflow-hidden">
                <div className="grid lg:grid-cols-[3fr_9fr] md:grid-cols-[4fr_8fr] sm:grid-cols-1 p-0 h-full">
                  <div className="container mx-auto p-0 sm:p-0 md:p-4 order-2 sm:order-2 md:order-1">
                    <div className="bg-background_white md:rounded-lg md:border-2 md:border-border_color p-3">
                      <h4 className="text-lg text-text_black font-medium text-gray-900 dark:text-white">
                        {t("hero.select_map")}
                      </h4>

                      <div style={{ position: "relative" }}>
                        {isPickupReadOnly() ? (
                          <>
                            <input
                              type="text"
                              placeholder={t("hero.pickup_text")}
                              style={{
                                width: "100%",
                                padding: "10px",
                                border: "1px solid #E9B838",
                                paddingRight: "35px",
                                outline: "none",
                                borderRadius: "7px",
                                marginTop: "10px",
                              }}
                              readOnly
                              value={location}
                            />
                            <span
                              style={{
                                position: "absolute",
                                right: "10px",
                                top: "60%",
                                transform: "translateY(-50%)",
                                pointerEvents: "none",
                              }}
                            >
                              <img
                                src={pickupIcon}
                                alt="Pickup"
                                height={20}
                                width={20}
                              />
                            </span>
                          </>
                        ) : (
                          <>
                            <Autocomplete
                              onLoad={onPickupSearchBoxLoad}
                              onPlacesChanged={onPlacesChangedPickup}
                              options={{
                                componentRestrictions: { country: "sa" },
                              }}
                            >
                              <input
                                type="text"
                                placeholder={t("hero.pickup_text")}
                                style={{
                                  width: "100%",
                                  padding: "10px",
                                  border: "1px solid #E9B838",
                                  paddingRight: "35px",
                                  outline: "none",
                                  borderRadius: "7px",
                                  marginTop: "10px",
                                }}
                                value={location}
                                onChange={(e) => setLocation(e.target.value)}
                              />
                            </Autocomplete>
                            <span
                              style={{
                                position: "absolute",
                                right: "10px",
                                top: "60%",
                                transform: "translateY(-50%)",
                                pointerEvents: "none",
                              }}
                            >
                              <img
                                src={pickupIcon}
                                alt="Pickup"
                                height={20}
                                width={20}
                              />
                            </span>
                          </>
                        )}
                      </div>

                      <div style={{ position: "relative" }}>
                        {(rideName === "airportRide" ||
                          rideName === "scheduledRide") &&
                          (isDropoffReadOnly() ? (
                            <>
                              <input
                                type="text"
                                placeholder={t("hero.dropoff_text")}
                                readOnly
                                style={{
                                  width: "100%",
                                  padding: "10px",
                                  border: "1px solid #E9B838",
                                  paddingRight: "35px",
                                  outline: "none",
                                  borderRadius: "7px",
                                  marginTop: "10px",
                                }}
                                value={destination}
                              />
                              <span
                                style={{
                                  position: "absolute",
                                  right: "10px",
                                  top: "60%",
                                  transform: "translateY(-50%)",
                                  pointerEvents: "none",
                                }}
                              >
                                <img
                                  src={dropoffIcon}
                                  alt="Pickup"
                                  height={20}
                                  width={20}
                                />
                              </span>
                            </>
                          ) : (
                            <>
                              <Autocomplete
                                onLoad={onDropoffSearchBoxLoad}
                                onPlacesChanged={onPlacesChangedDropoff}
                                options={{
                                  componentRestrictions: { country: "sa" },
                                  // You can set bounds here if necessary
                                }}
                              >
                                <input
                                  type="text"
                                  placeholder={t("hero.dropoff_text")}
                                  style={{
                                    width: "100%",
                                    padding: "10px",
                                    border: "1px solid #E9B838",
                                    paddingRight: "35px",
                                    outline: "none",
                                    borderRadius: "7px",
                                    marginTop: "10px",
                                  }}
                                  value={destination}
                                  onChange={(e) =>
                                    setDestination(e.target.value)
                                  }
                                />
                              </Autocomplete>
                              <span
                                style={{
                                  position: "absolute",
                                  right: "10px",
                                  top: "60%",
                                  transform: "translateY(-50%)",
                                  pointerEvents: "none",
                                }}
                              >
                                <img
                                  src={dropoffIcon}
                                  alt="Pickup"
                                  height={20}
                                  width={20}
                                />
                              </span>
                            </>
                          ))}
                      </div>

                      <Button
                        className="bg-background_steel_blue w-full text-text_white hover:bg-gray-100 font-medium text-sm px-5 py-2.5 mt-3"
                        label={t("confirm_text")}
                        onClick={submitDestination}
                      />
                      <Button
                        className="bg-background_white w-full text-text_black hover:bg-gray-100 font-medium text-sm px-5 py-2.5 mt-3"
                        label={t("previous_text")}
                        onClick={closeModal}
                      />
                    </div>
                    {error && (
                      <div className="p-4 text-text_warning">{error}</div>
                    )}
                  </div>
                  <div className="container mx-auto p-0 sm:p-0 md:p-4 order-1 sm:order-1 md:order-2">
                    <div
                      className={`md:border-2 md:rounded-lg border-0`}
                      style={{ borderColor: zoneCoords[0]?.color }}
                    >
                      <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={center}
                        zoom={10}
                        onLoad={onLoad}
                        onClick={(event) => onMapClick(event, null)}
                        options={mapOptions}
                      >
                        {/* Your existing map components */}
                        {convertedCoords.map((convertedCod, index) => (
                          <Polygon
                            paths={convertedCod}
                            options={{
                              fillColor: "#4463F0",
                              fillOpacity: 0.3,
                              strokeColor: "#355E3B",
                              strokeOpacity: 1,
                              strokeWeight: 1,
                            }}
                            onClick={(event) => onMapClick(event, index)}
                          />
                        ))}

                        {selectedPickup && (
                          <Marker
                            position={selectedPickup}
                            label={t("hero.pickup_text")}
                            icon={{ url: "./assets/map/pickup.png" }}
                            onClick={() => {
                              if (rideName === "airportRide") {
                                if (
                                  formValues.rideType ===
                                  t("hero.pickup_value_text")
                                ) {
                                  setSelectedPickup(selectedPickup);
                                } else {
                                  setSelectedPickup(null);
                                }
                              } else if (rideName === "rideByHour") {
                                setSelectedPickup(null);
                              } else if (rideName === "scheduledRide") {
                                setSelectedPickup(null);
                              }
                            }}
                          />
                        )}
                        {selectedDropoff && (
                          <Marker
                            position={selectedDropoff}
                            label={t("hero.dropoff_text")}
                            icon={{ url: "./assets/map/dropoff.png" }}
                            onClick={() => {
                              if (rideName === "airportRide") {
                                if (
                                  formValues.rideType ===
                                  t("hero.dropoff_value_text")
                                ) {
                                  setSelectedDropoff(selectedDropoff);
                                } else {
                                  setSelectedDropoff(null);
                                }
                              } else if (rideName === "rideByHour") {
                                setSelectedDropoff(null);
                              } else if (rideName === "scheduledRide") {
                                setSelectedDropoff(null);
                              }
                            }}
                          />
                        )}

                        {selectedPickup !== null || selectedDropoff !== null ? (
                          <Polyline
                            path={[selectedPickup, selectedDropoff]}
                            options={{
                              strokeColor: "#E9B838",
                              strokeOpacity: 1,
                              strokeWeight: 2,
                            }}
                          />
                        ) : null}

                        {/* {currentLocation && (
                      <Marker
                        position={currentLocation}
                        icon={{ url: "./assets/map/dropoff.png" }}
                        label="Current Location"
                      />
                    )} */}

                        {/* Standalone Search Box */}
                        {/* <StandaloneSearchBox
                      onLoad={onSearchBoxLoad}
                      onPlacesChanged={onPlacesChanged}
                    >
                      <input
                        type="text"
                        placeholder="Search for a place"
                        className="map-searchbox-setting"
                        // style={{
                        //   boxSizing: `border-box`,
                        //   border: `1px solid transparent`,
                        //   width: `240px`,
                        //   height: `40px`,
                        //   padding: `0 12px`,
                        //   borderRadius: `3px`,
                        //   boxShadow: `0 2px 6px rgba(0, 0, 0, 0.3)`,
                        //   fontSize: `14px`,
                        //   outline: `none`,
                        //   textOverflow: `hidden`,
                        //   position: "relative",
                        //   left: `${isMax768 ? "3%" : "50%"}`,
                        //   marginLeft: `${isMax768 ? "0px" : "-120px"}`,
                        //   top: topValue,
                        //   zIndex: 1,
                        // }}
                      />
                    </StandaloneSearchBox> */}

                        <button
                          onClick={handleCurrentLocation}
                          type="button"
                          className="absolute top-3 right-2 z-10 bg-background_white text-text_black p-2 rounded-lg"
                        >
                          <Icon
                            icon="basil:current-location-outline"
                            width="24"
                            height="24"
                          />
                        </button>
                      </GoogleMap>
                    </div>
                  </div>
                </div>
                {/* {error && <div className="p-4 text-text_warning">{error}</div>} */}
                {/* <div className="flex items-center p-4 md:p-5 space-x-3 rtl:space-x-reverse border-gray-200 rounded-b dark:border-gray-600">
                <button
                  onClick={submitDestination}
                  type="button"
                  className="text-background_steel_blue border bg-text_white border-text_white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  {t('hero.confirm_destination_text')}
                </button>
                <button
                  onClick={closeModal}
                  type="button"
                  className="py-2.5 px-5 ms-3 text-sm font-medium rounded-lg border border-background_white text-background_white"
                >
                  {t('hero.decline_text')}
                </button>
              </div> */}
              </div>
            </div>
          </LoadScript>
        </div>
      )}
    </>
  );
}
