import React from "react";
import { useSelector } from "react-redux";
import Header from "./components/base/Header";
import Footer from "./components/base/Footer";
import { useTranslation } from "react-i18next";
import FloatingSupportButton from "./components/base/FloatingSupportButton";

export default function Terms() {
  // const dispatch = useDispatch();
  const language = useSelector((state) => state.auth.language);
  const [t, i18n] = useTranslation("global");

  return (
    <div>
      <Header />
      <main className="mt-0 md:mt-20" dir={language === "ar" ? "rtl" : "ltr"}>
        <div className="mt-5 px-5 w-full">
          <h1 className="text-text_steel_blue font-bold text-center text-2xl md:text-4xl">
            {t("term.term_condition_text")}
          </h1>
          <h2 className="font-bold mt-3 text-lg">{t("term.intro_text")}:</h2>
          <p className="mt-2">{t("term.first_welcome_text")}</p>
          <h2 className="font-bold mt-3 text-lg">{t("term.accept_text")}:</h2>
          <p className="mt-3">{t("term.second_your_text")}</p>
          <h2 className="font-bold mt-3 text-xl">
            {t("term.condition_text")}:
          </h2>
          <h2 className="font-bold mt-3 text-lg">{t("term.account_text")}:</h2>
          <p className="mt-3">{t("term.third_must_text")}</p>

          <h2 className="font-bold mt-3 text-lg">{t("term.booking_text")}:</h2>
          <p className="mt-3">{t("term.booking_p1_text")}</p>
          <p className="mt-3">{t("term.booking_p2_text")}</p>
          <p className="mt-3">{t("term.booking_p3_text")}</p>
          <h2 className="font-bold mt-3 text-lg">
            {t("term.liability_text")}:
          </h2>
          <p className="mt-3">{t("term.liability_p1_text")}</p>
          <p className="mt-3">{t("term.liability_p2_text")}</p>
          <h2 className="font-bold mt-3 text-lg">
            {t("term.modification_text")}:
          </h2>
          <p className="mt-3">{t("term.modification_p_text")}</p>
          <h2 className="font-bold mt-3 text-lg">
            {t("term.jurisdiction_text")}:
          </h2>
          <p className="mt-3">{t("term.jurisdiction_p_text")}</p>
          <h2 className="font-bold mt-3 text-lg">{t("term.note_text")}:</h2>
          <p className="mt-3">{t("term.note_p1_text")}</p>
          <p className="mt-3">{t("term.note_p2_text")}</p>
          <p className="mt-3">{t("term.note_p3_text")}</p>
        </div>
      </main>
      <Footer />
      <FloatingSupportButton />
    </div>
  );
}
