import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";

const WelcomePopup = ({
  title = "Welcome to Our Site!",
  message = "Explore our services, offers, and more.",
  buttonText = "Close",
}) => {
  const [isPopupVisible, setPopupVisible] = useState(false);
  useEffect(() => {
    // Check if the "visited" cookie exists
    const hasVisited = Cookies.get("visited");
    if (!hasVisited) {
      // Show the popup if the user has not visited before
      setPopupVisible(true);
    }
  }, []);
  const handleClosePopup = () => {
    // Set the "visited" cookie to prevent showing the popup again
    Cookies.set("visited", "true", { expires: 7 }); // Cookie expires in 7 days
    setPopupVisible(false);
  };
  return (
    isPopupVisible && (
      <div style={styles.overlay} aria-hidden={!isPopupVisible}>
        <div
          style={styles.popup}
          role="dialog"
          aria-labelledby="popup-title"
          aria-describedby="popup-message"
        >
          <h2 id="popup-title" style={styles.title}>
            {title}
          </h2>
          <p id="popup-message" style={styles.message}>
            {message}
          </p>
          <button
            style={styles.button}
            onClick={handleClosePopup}
            aria-label="Close Welcome Popup"
          >
            {buttonText}
          </button>
        </div>
      </div>
    )
  );
};
const styles = {
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 1000,
    animation: "fadeIn 0.5s ease-in-out",
  },
  popup: {
    background: "#fff",
    padding: "20px",
    borderRadius: "10px",
    textAlign: "center",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
    maxWidth: "90%",
    margin: "0 auto",
  },
  title: {
    fontSize: "24px",
    fontWeight: "700",
    marginBottom: "15px", // Added margin below the title
  },
  message: {
    fontSize: "16px",
    marginBottom: "20px", // Added margin below the message
    marginTop: "10px", // Added margin above the message
    padding: "0px 50px",
  },
  button: {
    marginTop: "20px",
    padding: "10px 20px",
    backgroundColor: "#E9B838", // Updated background color
    color: "#fff",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
    transition: "background-color 0.3s ease",
  },
  "@keyframes fadeIn": {
    from: { opacity: 0 },
    to: { opacity: 1 },
  },
};
// Inject styles for the animation into the document head
const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = `
  @keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
  }
`;
document.head.appendChild(styleSheet);
export default WelcomePopup;
