import React, { useEffect, useMemo, useState } from "react";
import Stepper from "../base/Stepper";
import Button from "../base/Button";
import Heading from "../base/Heading";
import MapModal from "../base/MapModal";
import { useDispatch, useSelector } from "react-redux";
import { fetchCitiesRequest } from "../../../redux/actions/cityActions";
import { fetchVehicleTypesRequest } from "../../../redux/actions/vehicleTypeAction";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import InputFieldFormik from "../base/InputFieldFormik";
import { getZoneRequest } from "../../../redux/actions/zoneActions";
import HomeEmailSignUp from "./HomeEmailSignUp";
import { setLoading } from "../../../redux/actions/loaderAction";
import VehicleTypeModal from "../base/VehicleTypeModal";
import PaymentMethod from "./PaymentMethod";
// import axios from "axios";
import { message } from "antd";
import { useTranslation } from "react-i18next";
import axiosInstance from "../../../Api";

export default function ScheduledRide({
  subTab,
  setSubTab,
  showSignUp,
  setShowSignUp,
  showAlreadyRegistered,
  setShowAlreadyRegistered,
  showOTPScreen,
  setShowOTPScreen,
  hideCreateAccountButton,
  setHideCreateAccountButton,
  showPhone,
  setShowPhone,
  hidePhoneCreateAccountButton,
  setHidePhoneCreateAccountButton,
  showPhoneOTPScreen,
  setShowPhoneOTPScreen,
  showPaymentMethod,
  setShowPaymentMethod,
  recaptchaRef,
  otp,
  setOtp,
  phoneOtp,
  setPhoneOtp,
  showPaybylinkQr,
  setShowPaybylinkQr,
}) {
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const { cities } = useSelector((state) => state.cities);
  // const { vehicleTypes } = useSelector((state) => state.vehicleTypes);
  const language = useSelector((state) => state.auth.language);
  const zoneMap = useSelector((state) => state?.zone?.zone);
  const [map, setMap] = useState(null);
  useEffect(() => {
    setMap(zoneMap && zoneMap.length > 0 ? zoneMap : null);
  }, [zoneMap]);
  const services = "City Trip";
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);

  const [t, i18n] = useTranslation("global");

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [error, setError] = useState("");

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => {
    setIsModalOpen(false);
    setError("");
  };

  const [defaultVehicleTypeName, setDefaultVehicleTypeName] = useState("");
  const [getUsers, setGetUsers] = useState([]);
  const [seatNumberOptions, setSeatNumberOptions] = useState([]);
  const [selectedPickup, setSelectedPickup] = useState(null);
  const [selectedDropoff, setSelectedDropoff] = useState(null);
  const [arrivalDates, setArrivalDates] = useState(null);
  const [cityName, setCityName] = useState(null);
  const [formValues, setFormValues] = useState({
    agentUser: "",
    arrivalCity: "",
    arrivalDate: "",
    arrivalTime: "",
    vehicleType: "",
    seatNumber: "",
    sharedRide: false,
    location: "",
    destination: "",
  });
  const [onChangeFormValues, setOnChangeFormValues] = useState({
    agentUser: "",
    arrivalCity: "",
    arrivalDate: "",
    arrivalTime: "",
    vehicleType: "",
    seatNumber: "",
    sharedRide: false,
  });

  const [vehicleTypeName, setVehicleTypeName] = useState("");

  const [location, setLocation] = useState("");
  const [destination, setDestination] = useState("");

  const [VehicleTypeWithService, setVehicleTypeWithService] = useState(null);
  useEffect(() => {
    dispatch(setLoading(true));
    const getVechileTypes = async () => {
      if (cityName) {
        try {
          const response = await axiosInstance.get(
            `${API_BASE_URL}/api/method/airport_transport.api.bookings.get_vehicle_types?language=${
              language ? language : "en"
            }&service=City Trip&city=${cityName}`
          );
          if (response && response.status === 200) {
            setVehicleTypeWithService(response.data);
            const defaultVType = response.data.data.find(
              (type) => type.short_name === "VAN"
            );
            if (defaultVType.name) {
              setDefaultVehicleTypeName(defaultVType.name);
            }
          }
        } catch (error) {
          console.log("Error", error);
        }
      }
    };
    getVechileTypes();
    dispatch(setLoading(false));
  }, [cityName]);

  useEffect(() => {
    if (defaultVehicleTypeName) {
      setVehicleTypeName(defaultVehicleTypeName);
      setFormValues((preStates) => ({
        ...preStates,
        vehicleType: defaultVehicleTypeName,
      }));
    }
  }, [defaultVehicleTypeName]);

  useEffect(() => {
    if (vehicleTypeName !== "") {
      const selectedVehicle = VehicleTypeWithService.data.find(
        (vehicle) => vehicle.name === vehicleTypeName
      );
      setSeatNumberOptions(
        selectedVehicle
          ? Array.from({ length: selectedVehicle.seats }, (_, i) => `${i + 1}`)
          : []
      );
      setOnChangeFormValues((prevValues) => ({
        ...prevValues,
        ["vehicleType"]: vehicleTypeName,
      }));
    }
  }, [vehicleTypeName]);

  const handlePrevious = (step, values, location, destination) => {
    dispatch(setLoading(true));
    setFormValues((preStates) => ({
      ...preStates,
      location: location,
      destination: destination,
    }));
    setSubTab(step);
    dispatch(setLoading(false));
  };
  useEffect(() => {
    dispatch(fetchCitiesRequest());
    dispatch(fetchVehicleTypesRequest());
    const expectedCityName = cityName ? cityName : "Dammam";
    dispatch(getZoneRequest(services, expectedCityName));
  }, [dispatch, cityName]);

  const API_BASE_URL = process.env.REACT_APP_BASE_URL_AMK_TEST;

  const [showSharedRideValue, setShowSharedRideValue] = useState(false);
  const [sharedRideValue, setSharedRideValue] = useState("");
  useEffect(() => {
    const getSharedRideValue = async () => {
      dispatch(setLoading(true));
      if (vehicleTypeName !== "" && cityName) {
        try {
          const response = await axiosInstance.get(
            `${API_BASE_URL}/api/method/airport_transport.api.bookings.get_ride_discount?vehicle_type=${vehicleTypeName}&language=${language}`
          );
          if (response && response.status === 200) {
            console.log("response.data.data me kiya ha?", response.data.data);
            if (response.data.data > 0) {
              setShowSharedRideValue(true);
            }
            setSharedRideValue(response.data.data);
            dispatch(setLoading(false));
          }
        } catch (error) {
          console.log("Error", error);
          dispatch(setLoading(false));
        }
      }
      dispatch(setLoading(false));
    };
    getSharedRideValue();
  }, [vehicleTypeName]);

  useEffect(() => {
    const getUsers = async () => {
      dispatch(setLoading(true));

      try {
        const response = await axiosInstance.get(
          `${API_BASE_URL}/api/method/airport_transport.api.agent.get_transport_users`,
          {
            headers: {
              // 'Content-Type': 'application/x-www-form-urlencoded',
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response && response.status === 200) {
          const usersArray = response.data.data;
          const transformedEmails = usersArray.map((email) => ({
            value: email,
            label: email,
          }));
          setGetUsers(transformedEmails);
          dispatch(setLoading(false));
        }
      } catch (error) {
        console.log("Error", error);
        dispatch(setLoading(false));
      }

      dispatch(setLoading(false));
    };
    getUsers();
  }, [formValues]);

  const steps = useMemo(() => {
    const baseSteps = [
      { id: 1, text: t("hero.stepper_steps.ride_detail_text") },
      { id: 2, text: t("hero.stepper_steps.vehicle_detail_text") },
    ];

    if (!isLoggedIn) {
      baseSteps.push({
        id: 4,
        text: t("hero.stepper_steps.account_info_text"),
      });
    }

    return baseSteps;
  }, [isLoggedIn, t]);

  // const [subTab, setSubTab] = useState(1);

  const validationSchema = Yup.object().shape({
    agentUser: Yup.string().required("Agent User is required"),
    arrivalCity: Yup.string().required("Arrival City is required"),
    arrivalDate: Yup.string().required("Arrival Date is required"),
    arrivalTime: Yup.string().required("Arrival Time is required"),
    vehicleType: Yup.string().required("vehicle Type is required"),
    sharedRide: Yup.bool(),
    seatNumber: Yup.string().test(
      "seatNumber-required",
      "Seat Number is required",
      function (value) {
        const { sharedRide } = this.parent;
        return !(sharedRide && !value);
      }
    ),
  });

  const onSubmit = async (values, { setSubmitting }) => {
    dispatch(setLoading(true));

    if (isLoggedIn) {
      dispatch(setLoading(false));
      setShowPaymentMethod(true);
    } else {
      dispatch(setLoading(false));
      setSubTab(3);
      setShowSignUp(true);
    }

    setSubmitting(false);
    dispatch(setLoading(false));
  };

  const handleMapSubmit = (pickup, dropoff) => {
    setSelectedPickup(pickup);
    setSelectedDropoff(dropoff);
  };

  function formatLabel(text) {
    return text
      .replace(/([A-Z])/g, " $1") // Insert space before each uppercase letter
      .replace(/^./, (str) => str.toUpperCase()) // Capitalize the first character
      .trim(); // Remove leading spaces if any
  }

  useEffect(() => {
    if (arrivalDates) {
      setFormValues((preStates) => ({
        ...preStates,
        arrivalDate: arrivalDates,
      }));
    }
  }, [arrivalDates]);

  return (
    <>
      <div>
        {!showPaymentMethod && (
          <Stepper
            steps={steps}
            subTab={subTab}
            className={
              "flex items-center w-full text-sm font-medium text-center py-4 border-b text-gray-500 sm:text-base justify-between"
            }
          />
        )}

        <div>
          <div className="p-0 md:p-4">
            {showPaymentMethod ? (
              <PaymentMethod
                formValues={formValues}
                selectedPickup={selectedPickup}
                selectedDropoff={selectedDropoff}
                location={location}
                destination={destination}
                sharedRideValue={sharedRideValue}
                setSubTab={setSubTab}
                setShowSignUp={setShowSignUp}
                setShowAlreadyRegistered={setShowAlreadyRegistered}
                setShowOTPScreen={setShowOTPScreen}
                setHideCreateAccountButton={setHideCreateAccountButton}
                setShowPhone={setShowPhone}
                setHidePhoneCreateAccountButton={
                  setHidePhoneCreateAccountButton
                }
                setShowPhoneOTPScreen={setShowPhoneOTPScreen}
                setShowPaymentMethod={setShowPaymentMethod}
                showPaybylinkQr={showPaybylinkQr}
                setShowPaybylinkQr={setShowPaybylinkQr}
                rideName="City Trip"
              />
            ) : showSignUp ? (
              <>
                <HomeEmailSignUp
                  formValues={formValues}
                  setSubTab={setSubTab}
                  setShowSignUp={setShowSignUp}
                  showAlreadyRegistered={showAlreadyRegistered}
                  setShowAlreadyRegistered={setShowAlreadyRegistered}
                  showOTPScreen={showOTPScreen}
                  setShowOTPScreen={setShowOTPScreen}
                  setHideCreateAccountButton={setHideCreateAccountButton}
                  hideCreateAccountButton={hideCreateAccountButton}
                  showPhone={showPhone}
                  setShowPhone={setShowPhone}
                  hidePhoneCreateAccountButton={hidePhoneCreateAccountButton}
                  setHidePhoneCreateAccountButton={
                    setHidePhoneCreateAccountButton
                  }
                  showPhoneOTPScreen={showPhoneOTPScreen}
                  setShowPhoneOTPScreen={setShowPhoneOTPScreen}
                  showPaymentMethod={showPaymentMethod}
                  setShowPaymentMethod={setShowPaymentMethod}
                  recaptchaRef={recaptchaRef}
                  otp={otp}
                  setOtp={setOtp}
                  phoneOtp={phoneOtp}
                  setPhoneOtp={setPhoneOtp}
                  showPaybylinkQr={showPaybylinkQr}
                  setShowPaybylinkQr={setShowPaybylinkQr}
                />
              </>
            ) : (
              <Formik
                initialValues={formValues}
                validationSchema={validationSchema}
                enableReinitialize={true}
                onSubmit={onSubmit}
              >
                {({ values, errors, setFieldValue, validateForm }) => {
                  const isStep1Valid =
                    values.agentUser &&
                    values.arrivalCity &&
                    values.arrivalDate &&
                    values.arrivalTime;
                  const isStep2Valid =
                    // values.vehicleType &&
                    // values.seatNumber &&
                    // formValues.sharedRide === true ? values.seatNumber : '' &&
                    values.arrivalDate && values.arrivalTime;

                  return (
                    <Form className="mx-auto w-full">
                      {subTab === 1 && (
                        <>
                          <div>
                            <InputFieldFormik
                              label={t("user_text")}
                              name="agentUser"
                              type="select"
                              value={
                                formValues.agentUser ||
                                onChangeFormValues.agentUser
                              }
                              options={getUsers}
                              onChange={({ fieldName, selectedValue }) => {
                                setFieldValue(fieldName, selectedValue);
                                setFormValues((prevValues) => ({
                                  ...prevValues,
                                  [fieldName]: selectedValue,
                                }));
                                setOnChangeFormValues((prevValues) => ({
                                  ...prevValues,
                                  [fieldName]: selectedValue,
                                }));
                              }}
                              required
                            />

                            <InputFieldFormik
                              label={t("hero.arrival_city_text")}
                              name="arrivalCity"
                              type="select"
                              options={
                                cities &&
                                cities.data &&
                                cities.data.map((city) => ({
                                  value: city,
                                  label: city,
                                }))
                              }
                              value={
                                formValues.arrivalCity ||
                                onChangeFormValues.arrivalCity
                              }
                              onChange={(valueObj) => {
                                const { fieldName, selectedValue } = valueObj;
                                setFieldValue(fieldName, selectedValue);
                                setCityName(selectedValue);
                                setFormValues((prevValues) => ({
                                  ...prevValues,
                                  [fieldName]: selectedValue,
                                }));
                                setOnChangeFormValues((prevValues) => ({
                                  ...prevValues,
                                  [fieldName]: selectedValue,
                                }));
                              }}
                              required
                            />
                          </div>

                          <div>
                            <InputFieldFormik
                              label={t("hero.arrival_date_text")}
                              name="arrivalDate"
                              type="arrivalDate"
                              value={values.arrivalDate || ""}
                              arrivalDates={arrivalDates}
                              setArrivalDates={setArrivalDates}
                              // onChange={({ fieldName, selectedValue }) => {
                              //   setFieldValue(fieldName, selectedValue);
                              //   setOnChangeFormValues((prevValues) => ({
                              //     ...prevValues,
                              //     [fieldName]: selectedValue,
                              //   }));
                              // }}
                              onChange={({ date, dateString }) => {
                                setOnChangeFormValues((prevValues) => ({
                                  ...prevValues,
                                  ["arrivalDate"]: dateString,
                                }));
                                setFieldValue("arrivalDate", dateString);
                              }}
                              required
                            />
                          </div>

                          <div>
                            <InputFieldFormik
                              label={t("hero.arrival_time_text")}
                              name="arrivalTime"
                              type="arrivalTime"
                              value={values.arrivalTime || ""}
                              arrivalDates={arrivalDates}
                              onChange={({ fieldName, selectedValue }) => {
                                setFieldValue(fieldName, selectedValue);
                                setFormValues((prevValues) => ({
                                  ...prevValues,
                                  [fieldName]: selectedValue,
                                }));
                                setOnChangeFormValues((prevValues) => ({
                                  ...prevValues,
                                  [fieldName]: selectedValue,
                                }));
                              }}
                              required
                            />
                          </div>

                          <div className="mt-4">
                            <label className="block mb-2 mt-2 lg:mt-1 text-sm font-medium text-gray-900 dark:text-white rtl:text-right">
                              {t("hero.select_your_text")}
                            </label>
                            <button
                              onClick={openModal}
                              className="flex justify-between w-full bg-background_white text-gray-700 border border-border_color font-medium rounded-lg text-sm px-3 py-3 text-left"
                              type="button"
                              disabled={formValues.arrivalCity ? false : true}
                            >
                              <span>{t("hero.place_of_arrival_text")}</span>
                              <img src="./assets/map/map.png" alt="map" />
                            </button>
                            <div className="w-full md:w-1/2">
                              {formValues.arrivalCity && (
                                <MapModal
                                  error={error}
                                  setError={setError}
                                  isModalOpen={isModalOpen}
                                  setIsModalOpen={setIsModalOpen}
                                  openModal={openModal}
                                  closeModal={closeModal}
                                  rideName="scheduledRide"
                                  formValues={formValues}
                                  setFormValues={setFormValues}
                                  onSubmitDestination={handleMapSubmit}
                                  zoneCoords={map}
                                  cityName={values.arrivalCity}
                                  location={location}
                                  setLocation={setLocation}
                                  destination={destination}
                                  setDestination={setDestination}
                                />
                              )}
                            </div>
                          </div>

                          <div className="w-full mt-3">
                            <Button
                              className="bg-background_steel_blue w-full text-text_white hover:bg-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 mb-2"
                              onClick={() => {
                                dispatch(setLoading(true));
                                // Error messages for both English and Arabic
                                const errorMessages = {
                                  agentUser:
                                    language === "ar"
                                      ? "وكيل المستخدم مطلوب"
                                      : "Agent user is required",
                                  arrivalCity:
                                    language === "ar"
                                      ? "مدينة الوصول مطلوبة"
                                      : "Arrival city is required",
                                  arrivalDate:
                                    language === "ar"
                                      ? "تاريخ الوصول مطلوب"
                                      : "Arrival date is required",
                                  arrivalTime:
                                    language === "ar"
                                      ? "وقت الوصول مطلوب"
                                      : "Arrival time is required",
                                };
                                const requiredFields = [
                                  "agentUser",
                                  "arrivalCity",
                                  "arrivalDate",
                                  "arrivalTime",
                                ];
                                if (formValues.arrivalCity) {
                                  values.arrivalCity = formValues.arrivalCity;
                                }
                                const isStep1Valid = requiredFields.every(
                                  (field) => values[field]
                                );
                                if (!isStep1Valid) {
                                  requiredFields.forEach((field) => {
                                    if (!values[field]) {
                                      // Display the appropriate error message based on the language
                                      message.error(errorMessages[field]);
                                    }
                                  });
                                } else {
                                  if (
                                    !formValues.location ||
                                    !formValues.destination
                                  ) {
                                    message.error(
                                      t("hero.errors.map_required")
                                    );
                                  } else {
                                    setSubTab(2);
                                    setFormValues(values);
                                  }
                                }
                                dispatch(setLoading(false));
                              }}
                              label={t("next_text")}
                              type="button"
                              // disabled={!isStep1Valid}
                            />
                          </div>
                        </>
                      )}

                      {subTab === 2 && (
                        <>
                          <div>
                            <VehicleTypeModal
                              VehicleTypeWithService={VehicleTypeWithService}
                              vehicleTypeName={vehicleTypeName}
                              setVehicleTypeName={setVehicleTypeName}
                              setFormValues={setFormValues}
                            />
                            <ErrorMessage
                              name="vehicleType"
                              component="div"
                              className="error"
                              style={{ color: "red" }}
                            />
                          </div>

                          {showSharedRideValue && (
                            <div className="border border-bg_light_gray p-2 mt-3">
                              <p className="text-lg text-text_black font-bold">
                                {t("hero.shared_ride_text")}
                              </p>
                              <InputFieldFormik
                                // label={t("hero.shared_ride_text")}
                                name="sharedRide"
                                type="checkbox"
                                percentageValue={sharedRideValue}
                                onChange={({ fieldName, selectedValue }) => {
                                  setFieldValue(fieldName, selectedValue);
                                  setFormValues((prevValues) => ({
                                    ...prevValues,
                                    [fieldName]: selectedValue,
                                  }));
                                  setOnChangeFormValues((prevValues) => ({
                                    ...prevValues,
                                    [fieldName]: selectedValue,
                                  }));
                                }}
                                required
                              />
                            </div>
                          )}

                          {formValues.sharedRide && (
                            <div>
                              <InputFieldFormik
                                label={t("hero.seat_number_text")}
                                name="seatNumber"
                                type="select"
                                options={seatNumberOptions.map((number) => ({
                                  value: number,
                                  label: number,
                                }))}
                                value={
                                  formValues.seatNumber ||
                                  onChangeFormValues.seatNumber
                                }
                                onChange={(valueObj) => {
                                  const { fieldName, selectedValue } = valueObj;
                                  setFieldValue(fieldName, selectedValue);
                                  setFormValues((prevValues) => ({
                                    ...prevValues,
                                    [fieldName]: selectedValue,
                                  }));
                                  setOnChangeFormValues((prevValues) => ({
                                    ...prevValues,
                                    [fieldName]: selectedValue,
                                  }));
                                }}
                                required
                              />
                            </div>
                          )}

                          <div className="mt-3 flex flex-row justify-between items-center gap-2">
                            <div className="w-1/2 mx-0 md:mx-1">
                              <Button
                                className="bg-bg_btn_back w-full text-text_white hover:bg-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2"
                                onClick={() =>
                                  handlePrevious(
                                    1,
                                    values,
                                    location,
                                    destination
                                  )
                                }
                                label={t("previous_text")}
                                disabled={false}
                                type="button"
                              />
                            </div>
                            <div className="w-1/2 mx-0 md:mx-1">
                              <Button
                                className="bg-background_steel_blue cursor-pointer w-full text-text_white hover:bg-gray-100 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2"
                                label={t("submit_text")}
                                type="submit"
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </Form>
                  );
                }}
              </Formik>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
